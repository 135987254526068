import { environment } from "@/../environments/environment";
import customFetch from "@/helpers/customFetch";
import { PersonActionName } from "@/typedef";
import { DashboardPerson, PersonActionSource } from "@/types/personTypes";
import { get } from "lodash";
import {
  headersAndRowsToCsv,
  headersAndRowsToSpreadsheet,
} from "scanreach-frontend-components/src/utils/csvAndSpreadsheetUtils";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";
import { BoardingActionFullReadModel } from "./useGangway";

const genericApiAddress = environment.genericApiAddress;
export async function exportBoardingActions(
  personActionsQuery: PersonActionsQuery,
  format: "xlsx" | "csv",
  fileName = "Person Actions",
) {
  const queryParamString = queryToParams(
    {
      siteIds: personActionsQuery.commaSeparatedSiteIds,
      from: personActionsQuery.from,
      to: personActionsQuery.to,
      actionTypes: personActionsQuery.filters.boardingActions.length
        ? personActionsQuery.filters.boardingActions
        : undefined,
      actionSources: personActionsQuery.filters.actionSources.length
        ? personActionsQuery.filters.actionSources
        : undefined,
      locations: personActionsQuery.filters.locations?.length
        ? personActionsQuery.filters.locations
        : undefined,
      gangwayNames: personActionsQuery.filters.gangwayStations.length
        ? personActionsQuery.filters.gangwayStations
        : undefined,
    },
    false,
  );
  const resp = await customFetch(`${genericApiAddress}/person/GetBoardingActions${queryParamString}`, {
    method: "GET",
  });

  if (resp.ok) {
    const personActionsFromApi = (await resp.json()) as BoardingActionFullReadModel[];
    const headersAndRows = getHeadersAndRowsFromObjects(personActionsFromApi, personActionsQuery.columns);
    if (format === "xlsx") {
      headersAndRowsToSpreadsheet(headersAndRows.headers, headersAndRows.rows, fileName);
    } else {
      headersAndRowsToCsv(headersAndRows.headers, headersAndRows.rows, fileName);
    }
  } else {
    throw new Error(await resp.text());
  }
}

export async function exportPob(
  pobQuery: PobQuery,
  format: "xlsx" | "csv",
  fileName = "Personnel",
  agregation: ExportLogAgregation,
) {
  const queryParamString = queryToParams(
    {
      siteIds: pobQuery.commaSeparatedSiteIds,
      shifts: pobQuery.filters.shifts?.length ? pobQuery.filters.shifts : undefined,
      roles: pobQuery.filters.roles?.length ? pobQuery.filters.roles : undefined,
      companies: pobQuery.filters.companies?.length ? pobQuery.filters.companies : undefined,
      boardingStates: pobQuery.filters.boardingStates?.length ? pobQuery.filters.boardingStates : undefined,
    },
    false,
  );
  const resp = await customFetch(`${genericApiAddress}/person${queryParamString}`, {
    method: "GET",
  });

  if (resp.ok) {
    const personsFromApi = (await resp.json()) as DashboardPerson[];

    // do some magic to use agregation
    let headersAndRows;
    if (agregation.type == ExportLogAgregationType.None) {
      headersAndRows = getHeadersAndRowsFromObjects<DashboardPerson>(personsFromApi, pobQuery.columns);
    } else if (agregation.type == ExportLogAgregationType.CountByColumn && agregation.columnPath) {
      const property = agregation.columnPath;

      // Group and count occurrences of the property
      const grouped = personsFromApi.reduce((acc, obj) => {
        const value = get(obj, property) ?? "-";
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});

      // Transform the grouped object into an array of objects with headers matching the column structure
      const groupedArray = Object.entries(grouped).map(([value, count]) => ({
        [property]: value,
        count,
      }));

      // Define the columns dynamically if not already part of pobQuery.columns
      const columns = [
        {
          displayName: PobExportKnownProperties.find((p) => p.path == property)?.displayName ?? "Value",
          path: property,
        },
        { displayName: "Count", path: "count" },
      ];

      // Convert grouped data to headers and rows
      headersAndRows = getHeadersAndRowsFromObjects(groupedArray, columns);
    }

    if (format === "xlsx") {
      headersAndRowsToSpreadsheet(headersAndRows.headers, headersAndRows.rows, fileName);
    } else {
      headersAndRowsToCsv(headersAndRows.headers, headersAndRows.rows, fileName);
    }
  } else {
    throw new Error(await resp.text());
  }
}

/**
 * Converts a list of boarding actions to a list of headers and rows
 */
export function getHeadersAndRowsFromObjects<T>(
  objects: T[],
  columns: ExportLogProperty[],
): {
  headers: string[];
  rows: (string | number | null | undefined)[][];
} {
  return {
    headers: columns.map((c) => c.displayName),
    rows: objects.map((object) => columns.map((c) => get(object, c.path))),
  };
}

export const GangwayExportKnownProperties: (ExportLogProperty & { isDefault?: boolean })[] = [
  { displayName: "Date time UTC", path: "dateTimeUtc", isDefault: true },
  { displayName: "Boarding action", path: "action", isDefault: true },
  { displayName: "Location", path: "location", isDefault: true },
  { displayName: "Person Name", path: "person.fullName", isDefault: true },
  { displayName: "Person role", path: "person.role.name", isDefault: true },
  { displayName: "Gangway station", path: "gangwayEvent.gangway.label", isDefault: true },
  { displayName: "Cabin Number", path: "person.cabinNr" },
  { displayName: "Bunk Number", path: "person.bunkNr" },
  { displayName: "Red light violation", path: "violationType" },
  { displayName: "Date of Birth", path: "person.dateOfBirth" },
  { displayName: "Wearable mac", path: "person.wearable.mac" },
  { displayName: "Source", path: "actionSource" },

  // { displayName: "Boarding Action Id", path: "id", },
  // { displayName: "External person reference", path: "person.externalPersonReference.ExternalPersonId", },
];

export const PobExportKnownProperties: (ExportLogProperty & { isDefault?: boolean })[] = [
  { displayName: "Person Name", path: "fullName", isDefault: true },
  { displayName: "Role", path: "role.name", isDefault: true },
  { displayName: "Shift", path: "shift", isDefault: true },
  { displayName: "Company", path: "company", isDefault: true },
  { displayName: "Boarding state", path: "boardingState", isDefault: true },
  { displayName: "Cabin Number", path: "cabinNr" }, // TODO: Replace with new BunkManagement system
  { displayName: "Bunk Number", path: "bunkNr" }, // TODO: Replace with new BunkManagement system
  { displayName: "Date of Birth", path: "dateOfBirth" },
  { displayName: "Wearable mac", path: "wearable.mac" },
];
export function getDefaultGangwayExportProperties(): ExportLogProperty[] {
  return GangwayExportKnownProperties.filter((p) => p.isDefault);
}

export function getDefaultPobExportProperties(): ExportLogProperty[] {
  return PobExportKnownProperties.filter((p) => p.isDefault);
}

export type PobFilters = {
  boardingStates: PersonActionName[];
  /**
   * Name of each shift to filter for
   */
  shifts: string[];
  /**
   * Name of each role to filter for
   */
  roles: string[];
  /**
   * Name of each employer to filter for
   */
  companies: string[];
};

export type ExportLogAgregation = {
  type: ExportLogAgregationType;
  columnPath?: string;
};
export enum ExportLogAgregationType {
  None = "none",
  CountByColumn = "countByColumn",
}

export type ExportLogProperty = {
  displayName: string;
  path: string;
};

export type GangwayLogFilters = {
  boardingActions: PersonActionName[];
  actionSources: PersonActionSource[];
  /**
   * Name of each gangway station to filter for
   */
  gangwayStations: string[];
  /**
   * Name of each location to filter for
   */
  locations?: string[];
};

export type PersonActionsQuery = {
  commaSeparatedSiteIds?: string;
  from: Date;
  to: Date;
  columns: ExportLogProperty[];
  filters: GangwayLogFilters;
};

export type PobQuery = {
  commaSeparatedSiteIds?: string;
  columns: ExportLogProperty[];
  filters: PobFilters;
};
