import { render, staticRenderFns } from "./BunkSummary.vue?vue&type=template&id=dbb5c29a&scoped=true&"
import script from "./BunkSummary.vue?vue&type=script&lang=ts&"
export * from "./BunkSummary.vue?vue&type=script&lang=ts&"
import style0 from "./BunkSummary.vue?vue&type=style&index=0&id=dbb5c29a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbb5c29a",
  null
  
)

export default component.exports