import type { ApiNode } from "scanreach-frontend-components/src/types/ApiNode.type";
import type { Issue, IssueType, Severity } from "scanreach-frontend-components/src/types/Issue.type";
export enum PersonActionName {
  embark = "embark",
  disembark = "disembark",
  dropoff = "dropoff",
  unresolved = "unresolved",
}

export type DashboardNode = ApiNode & {
  sensorData?: SensorData;
  isMusterStation?: boolean; // populated by us off the 'musterStation' field for internal use
  isAlarmNode?: boolean;
  unlocated?: boolean;
  unresolvedIssueIds?: string[];
  peopleCount: NodePeopleCount;
};
export interface DashboardNodeWithMarkerPos extends DashboardNode {
  markerPos?: {
    lat: number;
    lng: number;
  } | null;
}

export interface DashboardWearable extends ApiWearable {
  sensorData?: SensorData;
  unresolvedIssueIds?: string[];
  unresolvedIssues?: Issue[];
}

export type NodePeopleCount = {
  peopleCount: number;
  [roleName: string]: number;
};
export type SensorData = {
  nodeId: string;
  voltage?: number | null;
  upTime: number;
  batteryPercentage?: number | null;
  rssi?: number | null;
  temperature?: number | null;
  alarm?: boolean;
  lastObservationTime?: Date | string | null;
  lastObservedNodeId?: string | null;
  nodeName?: string;
  wearableMacsAtNode?: string[];
};
export type SensorDataObject = { [mac: string]: SensorData };

export type SiteConfig = {
  view: ApiView;
  nodes: DashboardNode[] | ApiNode[];
  wearables: DashboardWearable[] | ApiWearable[];
  ioDevices: ApiIoDevice[];
  baseLayers: ApiBaseLayer[];
};

export type Snapshot = {
  nodes: {
    [nodeMac: string]: {
      peopleCount: number;
    };
  };
  peopleCount: PeopleCount;
};

export type PeopleCount = {
  total: number;
  missing: number;
  mustered: number;
  registered: number;
  musteredByRole: { [roleName: string]: number };
  notMusteredByRole: { [roleName: string]: number };
  musteredPeopleIds: string[];
  notMusteredPeopleIds: string[];
  registeredPeopleIds: string[];
};

export interface EventChangeMessage extends ApiChangeMessageBase, ApiEvent {}

export interface TagChangeMessage extends ApiChangeMessageBase, ApiWearable {}

export interface NodeChangeMessage extends ApiChangeMessageBase, ApiNode {}

export type ConfigurationChangeMessage = ApiChangeMessageBase;

export interface ApiChangeMessageBase {
  messageCreatedUtc: Date;
}

export type Guid = string;

export interface ApiEvent {
  id: Guid;
  eventType: EventType;
  activatedTime: Date;
  acknowledgedTime: Date | null;
  resolvedTime: Date | null;
  lastObservedUtc: Date | null;
  tag: ApiWearable | null;
  node: ApiNode | null;
  isConnectedNodeOrPersonActive: boolean | null;
}

export interface ApiEventQuery {
  Id?: Guid;
  EventTypeName?: EventTypeName;
  Acknowledged?: boolean;
  Resolved?: boolean;
  CreatedAfterUtc?: string;
  Page?: number;
  Limit?: number;
}

export interface ApiWriteEvent {
  eventTypeName: EventTypeName;
  acknowledgedUtc?: Date | null;
  resolvedUtc?: Date | null;
  lastObservedUtc?: Date | null;
  tagId?: Guid | null;
  nodeId?: Guid | null;
}

export interface EventType {
  id: Guid;
  name: EventTypeName;
}

export enum EventTypeName {
  TagButtonAlarm = "TagButtonAlarm",
  TagMissing = "TagMissing",
  TagLowBattery = "TagLowBattery",
  NodeMissing = "NodeMissing",
  NodeLowBattery = "NodeLowBattery",
  Mustering = "Mustering",
  AlarmNodeMissing = "AlarmNodeMissing",
  AlarmNodeBattery = "AlarmNodeBattery",
}

export interface ApiWearable {
  id: Guid;
  mac: string;
}

export interface ApiIoDevice {
  id: Guid;
  deviceName: string;
  model: string;
  macAddress: string;
  ipAddress: string;
}
export interface ApiView {
  id: Guid;
  width: number;
  height: number;
  name: string;
}
export interface ApiBaseLayer {
  id: Guid;
  name: string;
  imageReference: string;
}

// =================================
// ========  USER ==================
// =================================

export interface LoginModel {
  userNameOrEmail: string;
  password: string;
  longLived?: boolean;
}

export interface User {
  id: Guid;
  userName: string;
  email: string;
  name: string;
  phoneNumber: string;
  roles: RoleName[];
}

export enum RoleName {
  admin = "Admin",
  operator = "Operator",
  configurator = "Configurator",
}

export interface ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
}

export interface CustomFetchOptions {
  showWarning?: boolean;
}

// =================================
// ========  SystemStatus ==========
// =================================

export interface SystemStatus {
  type: StatusType;
  severity: Severity;
  lastRunTime: Date | null;
  children: SystemStatus[] | null;
  issueTypes: IssueType[] | null;
}

export enum StatusType {
  "System",

  "Hardware",
  "Node",
  "NodeBattery",
  "NodeConnection",
  "Wearable",
  "WearableMissing",
  "WearableBattery",

  "Gateway",
  "StreamProcessor",
  "UniSea",
  "SnapshotWorker",
  "CloudSync",
}

export type DashboardIssue = Issue & {
  isConnectedNodeOrPersonActive?: boolean | null;
};
