var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bunk-details"},[_c('div',{staticClass:"details-header"},[(_vm.selectedBunkIds.length <= 1)?_c('div',{staticClass:"bunk-details-info-header"},[_c('BunkIcon',{attrs:{"size":"24","iconColor":"var(--color-pop-01)"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.bunk?.cabinNr)+" "+_vm._s(_vm.bunk?.bunkNr))]),_c('BunkStatusPill',{attrs:{"disabledTime":_vm.updatedBunk.disabledDateTime,"bunkStatus":_vm.bunkStatus}})],1):_c('div',[_c('div',{staticClass:"title"},[_vm._v("SELECTED BUNKS SUMMARY ("+_vm._s(_vm.selectedBunkIds.length)+")")])]),_c('div',{staticClass:"close-icon",on:{"click":function($event){return _vm.$emit('close')}}},[_c('close-icon',{attrs:{"size":"24","iconColor":"var(--color-brand-01)"}})],1)]),_c('div',{staticClass:"bunk-details-info-container"},[(_vm.selectedBunkIds.length > 1)?_c('div',{staticClass:"bunk-chart-info"},[_c('chart-donut',{attrs:{"initialValues":_vm.countValues}}),_c('ul',{staticClass:"info-list info-bunks"},_vm._l((_vm.countValues),function(bunkStatus){return _c('li',{key:'bunkStatus-' + bunkStatus.name},[_c('div',{staticClass:"category-circle",style:({ background: bunkStatus.color })}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: bunkStatus.name,
              delay: {
                show: 500,
              },
            }),expression:"{\n              content: bunkStatus.name,\n              delay: {\n                show: 500,\n              },\n            }"}],staticClass:"label"},[_vm._v(" "+_vm._s(bunkStatus.name)+" ")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(bunkStatus.count))])])}),0)],1):_vm._e(),_c('div',{staticClass:"bunk-details-form"},[_c('form',{attrs:{"action":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('SInput',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.bunk
              ? `${_vm.bunk.cabinNr} ${_vm.bunk.bunkNr}`
              : Array.isArray(_vm.bunks)
                ? _vm.bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')
                : '',
            placement: 'bottom',
          }),expression:"{\n            content: bunk\n              ? `${bunk.cabinNr} ${bunk.bunkNr}`\n              : Array.isArray(bunks)\n                ? bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')\n                : '',\n            placement: 'bottom',\n          }"}],attrs:{"value":_vm.bunk
              ? `${_vm.bunk.cabinNr} ${_vm.bunk.bunkNr}`
              : Array.isArray(_vm.bunks)
                ? _vm.bunks.map((b) => `${b.cabinNr} ${b.bunkNr}`).join(', ')
                : '',"disabled":true,"label":"BUNK"}}),(_vm.selectedBunkIds.length <= 1 && _vm.bunk)?_c('div',{staticClass:"bunk-booking-info"},[_c('div',{staticClass:"reservations-container"},[_c('SLabel',{attrs:{"for":"currentResidents"}},[_vm._v("Current Reservation")]),_vm._l((_vm.bunk.currentResidents),function(reservation){return _c('BunkReservationItem',{key:reservation.cabinBunkId + Math.random(),attrs:{"bunkReservation":reservation,"isCurrentReservation":true},on:{"changeBunk":function($event){_vm.changeBunkModalTargetReservation = reservation}}})}),(!_vm.bunk.currentResidents || _vm.bunk.currentResidents.length == 0)?_c('div',{staticClass:"no-reservation"},[_vm._v(" No reservation ")]):_vm._e()],2),_c('div',{staticClass:"reservations-container"},[_c('SLabel',[_vm._v("Future Reservations")]),_vm._l((_vm.bunk.plannedReservations),function(reservation){return _c('BunkReservationItem',{key:reservation.cabinBunkId + Math.random(),attrs:{"bunkReservation":reservation},on:{"changeBunk":function($event){_vm.changeBunkModalTargetReservation = reservation}}})}),(!_vm.bunk.plannedReservations || _vm.bunk.plannedReservations.length == 0)?_c('div',{staticClass:"no-reservation"},[_vm._v(" No reservation ")]):_vm._e()],2),_c('portal',{attrs:{"to":"modal"}},[(_vm.changeBunkModalTargetReservation)?_c('AssignBunkModal',{attrs:{"existingReservation":_vm.changeBunkModalTargetReservation,"person":_vm.changeBunkModalTargetReservation.person},on:{"close":function($event){_vm.changeBunkModalTargetReservation = undefined}}}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"muster-station-selection-container"},[_c('MusterStationsPerMusterTypeSelectors'),(
              (_vm.selectedBunkIds.length <= 1
                ? (_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 1) ||
                  !_vm.updatedBunk.disabledDateTime
                : _vm.bunks.every(
                    (b) =>
                      _vm.selectedBunkIds.includes(b.id) &&
                      _vm.bunk?.currentResidents &&
                      b.currentResidents &&
                      b.currentResidents.length > 1,
                  )) && _vm.isMusterStationPerMusterTypeChanged
            )?_c('InfoBox',{attrs:{"iconColor":"var(--color-status-alert)"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(" When updating muster stations it will update the muster stations of all current residents from the selected bunks. Make sure this is the desired action. If not, we recommend that you unassign the resident(s) from the bunk(s) before updating the muster stations. ")])]},proxy:true}],null,false,852420299)}):_vm._e()],1),_c('div',{staticClass:"form-buttons-container"},[_c('NormalButton',{attrs:{"type":"button","icon":"","tertiary":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('CancelIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-01)"}}),_vm._v(" Cancel ")],1),_c('NormalButton',{attrs:{"type":"submit","icon":"","primary":"","disabled":!_vm.isChanged}},[_c('SaveIcon',{attrs:{"size":"16","iconColor":_vm.isChanged ? 'var(--color-pop-01)' : 'var(--color-brand-03)'}}),_vm._v(" Save ")],1)],1)],1)]),_c('section',{staticClass:"button-section"},[_c('div',{staticClass:"top-buttons"},[_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus !== _vm.BunkStatus.DISABLED : false) ||
              _vm.bunks.every((b) => b.disabledDateTime === null)
                ? `<center style='width: fit-content;'>${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is enabled<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              (selectedBunkIds.length === 1 ? bunkStatus !== BunkStatus.DISABLED : false) ||\n              bunks.every((b) => b.disabledDateTime === null)\n                ? `<center style='width: fit-content;'>${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} is enabled<center>`\n                : '',\n            html: true,\n          }"}],attrs:{"primary":"","disabled":(_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus !== _vm.BunkStatus.DISABLED : false) ||
            _vm.bunks.every((b) => b.disabledDateTime === null)},nativeOn:{"click":function($event){return _vm.handleChangeOfBunkActiveState(true)}}},[_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `ENABLE ${_vm.selectedBunkIds.length} BUNKS` : "ENABLE BUNK")+" ")]),_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              _vm.bunks.every((b) => b.disabledDateTime) ||
              (_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 0) ||
              _vm.bunks.some(
                (b) =>
                  _vm.selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,
              ) ||
              (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus === _vm.BunkStatus.DISABLED : false)
                ? `<center style='width: 300px'>Not possible to disable ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more Bunks'is occupied by a resident<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              bunks.every((b) => b.disabledDateTime) ||\n              (bunk?.currentResidents && bunk?.currentResidents?.length > 0) ||\n              bunks.some(\n                (b) =>\n                  selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,\n              ) ||\n              (selectedBunkIds.length === 1 ? bunkStatus === BunkStatus.DISABLED : false)\n                ? `<center style='width: 300px'>Not possible to disable ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more Bunks'is occupied by a resident<center>`\n                : '',\n            html: true,\n          }"}],attrs:{"secondary":"","disabled":_vm.bunks.every((b) => b.disabledDateTime) ||
            (_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 0) ||
            _vm.bunks.some(
              (b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,
            ) ||
            (_vm.selectedBunkIds.length === 1 ? _vm.bunkStatus === _vm.BunkStatus.DISABLED : false)},nativeOn:{"click":function($event){return _vm.handleChangeOfBunkActiveState(false)}}},[_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `DISABLE ${_vm.selectedBunkIds.length} BUNKS` : "DISABLE BUNK")+" ")])],1),_c('div',{staticClass:"bottom-buttons",class:_vm.selectedBunkIds.length > 1 ? 'flex-column' : ''},[_c('NormalButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              (_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 0) ||
              _vm.bunks.some(
                (b) =>
                  _vm.selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,
              )
                ? `<center style='width: 300px'>Not possible to delete ${_vm.selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more Bunks is occupied by a resident<center>`
                : '',
            html: true,
          }),expression:"{\n            content:\n              (bunk?.currentResidents && bunk?.currentResidents?.length > 0) ||\n              bunks.some(\n                (b) =>\n                  selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,\n              )\n                ? `<center style='width: 300px'>Not possible to delete ${selectedBunkIds.length > 1 ? 'Bunks' : 'Bunk'} when one or more Bunks is occupied by a resident<center>`\n                : '',\n            html: true,\n          }"}],staticStyle:{"max-width":"fit-content"},attrs:{"icon":"","disabled":(_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 0) ||
            _vm.bunks.some(
              (b) => _vm.selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,
            ),"tertiary":""},nativeOn:{"click":function($event){return _vm.$emit('deleteBunk')}}},[_c('ThrashBinIcon',{attrs:{"size":"16px","iconColor":(_vm.bunk?.currentResidents && _vm.bunk?.currentResidents?.length > 0) ||
              _vm.bunks.some(
                (b) =>
                  _vm.selectedBunkIds.includes(b.id) && b.currentResidents && b.currentResidents?.length > 0,
              )
                ? 'var(--color-brand-03)'
                : 'var(--color-brand-01)'}}),_vm._v(" "+_vm._s(_vm.selectedBunkIds.length > 1 ? `DELETE ${_vm.selectedBunkIds.length} BUNKS` : "DELETE BUNK")+" ")],1),_c('BunksExporter',{attrs:{"bunks":_vm.bunks,"selectedBunkIds":_vm.selectedBunkIds}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }