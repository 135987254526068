var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"muster-station-per-muster-type-selector-container"},_vm._l((_vm.musterTypes),function(musterType){return _c('div',{key:musterType.id},[_c('SLabel',{staticStyle:{"padding-bottom":"8px"},attrs:{"for":musterType.id}},[_vm._v("Muster station - "+_vm._s(musterType.label))]),_c('div',{staticClass:"muster-station-selectors"},[_c('VSelect',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTooltip),expression:"disabledTooltip"}],attrs:{"id":musterType.id + '-primary',"deselectFromDropdown":"","clearable":true,"append-to-body":"","calculatePosition":_vm.VSelectPopperPositioning,"reduce":(option) => option.id,"getOptionLabel":(option) => option.label,"disabled":_vm.isPersonAssignedToBunk || _vm.isExternalSystemOwnerOfData,"placeholder":_vm.updatedMusterStationsPerMusterTypes.find((muster) => muster.musterTypeId === musterType.id)
            ?.isPrimaryMixed
            ? 'Mixed'
            : 'Select the primary muster station',"options":_vm.musterStations,"value":_vm.updatedMusterStationsPerMusterTypes.find((muster) => muster.musterTypeId === musterType.id)
            ?.primaryMusterStationId},on:{"input":(primaryMusterStationId) => _vm.handleMusterStationUpdate(musterType.id, primaryMusterStationId, true)},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected-option"},[_c('p',{staticClass:"selected-option-prefix"},[_vm._v("1.")]),_c('div',{staticClass:"selected-option-label"},[(_vm.musterStations.find((station) => station.id === option.id))?[_vm._v(" "+_vm._s(option.label)+" ")]:[_vm._v(" Not found "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: `<center style='width: 300px'>Previously selected muster station was deleted. Please, select a new one</center>`,
                    html: true,
                  }),expression:"{\n                    content: `<center style='width: 300px'>Previously selected muster station was deleted. Please, select a new one</center>`,\n                    html: true,\n                  }"}],staticClass:"alarm-warning-badge",style:({ 'background-color': 'var(--color-status-alert)' })},[_vm._v(" ! ")])]],2)])]}},{key:"option",fn:function(musterStation){return [_c('div',{staticClass:"muster-station-option"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"main"},[_vm._v(_vm._s(musterStation.label))]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.nodes .filter((n) => n.musterStation?.id === musterStation.id) .map((n) => n.mac) .join(", "))+" ")])]),_c('div',{staticClass:"right"})])]}},{key:"no-options",fn:function({ search }){return [(search)?_c('p',[_vm._v("No matching muster stations found")]):_c('p',[_vm._v("No muster stations to choose from")])]}}],null,true)}),_c('VSelect',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabledTooltip),expression:"disabledTooltip"}],attrs:{"id":musterType.id + '-secondary',"deselectFromDropdown":"","clearable":true,"append-to-body":"","calculatePosition":_vm.VSelectPopperPositioning,"reduce":(option) => option.id,"getOptionLabel":(option) => option.label,"disabled":_vm.isPersonAssignedToBunk ||
          _vm.isExternalSystemOwnerOfData ||
          !_vm.isPrimaryMusterStationSelected(musterType.id),"placeholder":_vm.updatedMusterStationsPerMusterTypes.find((muster) => muster.musterTypeId === musterType.id)
            ?.isSecondaryMixed
            ? 'Mixed'
            : 'Select the secondary muster station, if desirable',"options":_vm.musterStations,"value":_vm.updatedMusterStationsPerMusterTypes.find((muster) => muster.musterTypeId === musterType.id)
            ?.secondaryMusterStationId},on:{"input":(secondaryMusterStationId) =>
            _vm.handleMusterStationUpdate(musterType.id, secondaryMusterStationId, false)},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected-option"},[_c('p',{staticClass:"selected-option-prefix"},[_vm._v("2.")]),_c('div',{staticClass:"selected-option-label"},[(_vm.musterStations.find((station) => station.id === option.id))?[_vm._v(" "+_vm._s(option.label)+" ")]:[_vm._v(" Not found "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: `<center style='width: 300px'>Previously selected muster station was deleted. Please, select a new one</center>`,
                    html: true,
                  }),expression:"{\n                    content: `<center style='width: 300px'>Previously selected muster station was deleted. Please, select a new one</center>`,\n                    html: true,\n                  }"}],staticClass:"alarm-warning-badge",style:({ 'background-color': 'var(--color-status-alert)' })},[_vm._v(" ! ")])]],2)])]}},{key:"option",fn:function(musterStation){return [_c('div',{staticClass:"muster-station-option"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"main"},[_vm._v(_vm._s(musterStation.label))]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.nodes .filter((n) => n.musterStation?.id === musterStation.id) .map((n) => n.mac) .join(", "))+" ")])]),_c('div',{staticClass:"right"})])]}},{key:"no-options",fn:function({ search }){return [(search)?_c('p',[_vm._v("No matching muster stations found")]):_c('p',[_vm._v("No muster stations to choose from")])]}}],null,true)})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }