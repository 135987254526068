import { onMounted, ref } from "vue";
import signalRSocketHandler from "../helpers/signalRSocketHandler";
import { deletePersonGroup, fetchPersonGroups, postPersonGroup } from "@/helpers/personService";
import { PersonGroup } from "@/types/personTypes";

const personGroups = ref<PersonGroup[]>([]);
const isLoading = ref(false);

const initialLoaded = ref(false);

/**
 * Used in crew planning to group people together, for example by "onboarding" or "offboarding"
 */
export default function () {
  onMounted(() => {
    if (!initialLoaded.value) {
      initialLoaded.value = true;

      if (process.env.NODE_ENV != "test") {
        fetchAndStorePersonGroups();
        subscribeToReconnectAndBroadSignalREvents();
      }
    }
  });

  async function fetchAndStorePersonGroups(): Promise<PersonGroup[]> {
    isLoading.value = true;
    try {
      personGroups.value = await fetchPersonGroups();
      return personGroups.value;
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  function subscribeToReconnectAndBroadSignalREvents() {
    signalRSocketHandler.subscribe("reconnect", () => {
      fetchAndStorePersonGroups();
    });
    signalRSocketHandler.subscribe("statuschange", (status) => {
      if (status.connected && !personGroups.value && !isLoading.value) {
        fetchAndStorePersonGroups();
      }
    });
    signalRSocketHandler.on("ReceiveConfigurationChangeEvent", () => {
      // Fetch all data again as there have been a CloudSync event
      fetchAndStorePersonGroups();
    });

    signalRSocketHandler.on("ReceivePersonGroupChangeEvent", (newPersonGroup) => {
      const existingIndex = personGroups.value.findIndex((group) => group.label == newPersonGroup.label);
      if (newPersonGroup.deletedUtcDateTime) {
        if (existingIndex >= 0) {
          personGroups.value = personGroups.value.splice(existingIndex, 1);
        }
        return;
      }

      if (existingIndex >= 0) {
        personGroups.value[existingIndex].label = newPersonGroup.label;
        personGroups.value[existingIndex].deletedUtcDateTime = newPersonGroup.deletedUtcDateTime;
      } else {
        personGroups.value.push(newPersonGroup);
      }
    });
  }

  return {
    isLoading,
    personGroups,
    postPersonGroup,
    deletePersonGroup,
  };
}
