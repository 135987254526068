import { environment } from "@/../environments/environment";
import customFetch from "@/helpers/customFetch";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import {
  Gangway,
  GangwayAction,
  GangwayEventState,
  GangwayLocation,
  GangwayLocationWithNewProp,
  GangwayLocationWrapper,
  GangwayWriteModel,
  IoDeviceConnectionChangeEvent,
  PersonActionWithPerson,
  SignalLight,
  SpatialDataObject,
  TrafficLight,
  TrafficLightStatus,
  TrafficLightStatusEvent,
  VesselLocation,
} from "@/types/gangwayTypes";
import { ApiPerson, ApiPersonAction, DashboardPerson } from "@/types/personTypes";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";
import { computed, ComputedRef, onMounted, ref } from "vue";

const genericApiAddress = environment.genericApiAddress;
const apiAddress = environment.apiAddress;

/**
 * List of unapproved gangwayActions
 */
const gangwayActionsFromApi = ref<GangwayAction[] | null>(null);
let gangwayActions: ComputedRef<GangwayAction[] | null | undefined>;
/**
 * List of last x personActions, either approved gangwayActions or manual actions performed.
 */
const last500PersonActions = ref<PersonActionWithPerson[] | null>(null);
const gangwayLocations = ref<GangwayLocationWrapper | null>(null);
const gangways = ref<Gangway[] | null>(null);
const vesselLocation = ref<VesselLocation | null>(null);
const isVesselLocationAutoMode = ref<boolean>(true);
const spatialDataObjectWithinRange = ref<SpatialDataObject | null>(null);
const isLoading = ref(false);
const isFetchDataFromApiInProgress = ref(false);
const isConfigBeingSaved = ref(false);

const initialLoaded = ref(false);

const trafficLightIdToStatus = ref<TrafficLightStatusEvent[]>([]);
const trafficLights = ref<TrafficLight[] | null>(null);
const signalLights = ref<SignalLight[] | null>(null);

export default function (store: any) {
  if (!initialLoaded.value) {
    initialLoaded.value = true;
    // Ensures that gangwayActions are connected to their correct person from vuex
    gangwayActions = computed(() =>
      gangwayActionsFromApi.value?.map((a) => ({
        ...a,
        person: store.getters.getPersonById(a.personId) as DashboardPerson | undefined | null,
      })),
    );
    onMounted(async () => {
      if (process.env.NODE_ENV != "test") {
        subscribeToReconnectAndBroadSignalREvents();
      }
    });
  }

  /**
   * Will be called when signalR is connected
   */
  async function fetchDataFromApi() {
    if (isFetchDataFromApiInProgress.value) {
      console.warn(
        "useGangway is already fetching data from api, skipping this call. This should not happen.",
      );
      return;
    }
    try {
      isFetchDataFromApiInProgress.value = true;
      await Promise.all([
        fetchGangways(),
        fetchGangwayActions(),
        fetchGangwayLocations(),
        fetchPersonActions(),
        fetchAndStoreTrafficLights(),
        fetchAndStoreSignalLights(),
        fetchTrafficLightStatuses(),
        fetchCurrentVesselLocation(),
        fetchVesselLocationMode(),
        getCachedSpatialDataObjectWithinRange(),
      ]);
    } finally {
      isFetchDataFromApiInProgress.value = false;
    }
  }

  /**
   * Approve or reject automatic gangwayActions
   * @param actions List of gangwayActions
   * @param approve Whether to approve or reject actions
   * @param location Id of the desired dropoff location. Only used when approving
   */
  async function approveGangwayActions(actions: GangwayAction[], approve: boolean, location?: string) {
    const actionsToSend = {
      location: location,
      events: actions.map((item) => ({
        id: item.id,
        approve: approve,
      })),
    };
    const resp = await customFetch(`${apiAddress}/gangwayEvents/handle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(actionsToSend),
    });

    if (!resp.ok) {
      throw new Error(await resp.text());
    }
  }

  /**
   * Fetch automatic gangway actions from api.
   * You can discard the returned value and instead listen for changes on gangwayActions
   */
  async function fetchGangwayActions(): Promise<GangwayAction[]> {
    isLoading.value = true;
    try {
      const queryParamString = queryToParams({
        state: GangwayEventState.Unhandled,
      });
      const resp = await customFetch(`${apiAddress}/gangwayEvents${queryParamString}`, {
        method: "GET",
      });

      if (resp.ok) {
        const actionsFromApi = (await resp.json()) as GangwayAction[];
        gangwayActionsFromApi.value = actionsFromApi;
        return actionsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Fetch approved personActions from api.
   * You can discard the returned value and instead listen for changes on personActions
   */
  async function fetchPersonActions(setLoading = true): Promise<PersonActionWithPerson[]> {
    isLoading.value = setLoading ? true : isLoading.value;
    try {
      const queryParamString = queryToParams({
        limit: 500,
      });
      const resp = await customFetch(`${apiAddress}/personActions${queryParamString}`, {
        method: "GET",
      });

      if (resp.ok) {
        const personActionsFromApi = (await resp.json()) as PersonActionWithPerson[];
        last500PersonActions.value = personActionsFromApi;
        return personActionsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Fetch gangwayLocations from api.
   * You can discard the returned value and instead listen for changes on gangwayLocations
   */
  async function fetchGangwayLocations(): Promise<GangwayLocationWrapper> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/GangwayLocation`, {
        method: "GET",
      });

      if (resp.ok) {
        const gangwayLocationsFromApi = (await resp.json()) as GangwayLocationWrapper;
        gangwayLocations.value = gangwayLocationsFromApi;
        return gangwayLocationsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Removes specified gangway location
   * @param gangwayLocationId
   */
  async function deleteGangwayLocation(gangwayLocationId: string): Promise<void> {
    const resp = await customFetch(`${apiAddress}/GangwayLocation/${gangwayLocationId}`, {
      method: "DELETE",
    });

    if (resp.ok) {
      return;
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * Updates the location of a gangway by sending a PUT request to the API.
   *
   * @param {GangwayLocation} gangwayLocation - The gangway location object containing the updated information.
   * @throws {Error} Throws an error if the response is not ok.
   * @returns {Promise<void>} A promise that resolves when the update is complete.
   */
  async function updateGangwayLocation(gangwayLocation: GangwayLocation) {
    isConfigBeingSaved.value = true;
    const apiUrl = `${apiAddress}/GangwayLocation/${gangwayLocation.id}`;
    const method = "PUT";

    try {
      const resp = await customFetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gangwayLocation),
      });

      if (!resp.ok) {
        throw new Error(await resp.text());
      }
    } catch (error) {
      console.error("Failed to update gangway location:", error);
      throw error;
    } finally {
      isConfigBeingSaved.value = false;
    }
  }

  /**
   * Adds a new gangway location by sending a POST request to the specified API endpoint.
   *
   * @param {GangwayLocationWithNewProp} gangwayLocation - The gangway location data to be added.
   * @returns {Promise<void>} A promise that resolves when the gangway location is successfully added.
   * @throws {Error} Throws an error if the request fails.
   */
  async function addNewGangwayLocation(gangwayLocation: GangwayLocationWithNewProp) {
    isConfigBeingSaved.value = true;
    const apiUrl = `${apiAddress}/GangwayLocation`;
    const method = "POST";

    try {
      const resp = await customFetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gangwayLocation),
      });

      if (!resp.ok) {
        throw new Error(await resp.text());
      }
    } catch (error) {
      console.error("Failed to add new gangway location:", error);
      throw error;
    } finally {
      isConfigBeingSaved.value = false;
    }
  }

  async function fetchGangways(): Promise<Gangway[]> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/Gangway`, {
        method: "GET",
      });

      if (resp.ok) {
        const data = (await resp.json()) as Gangway[];
        gangways.value = data;
        return gangways.value;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Fetches the list of available traffic-light hardware onBoard and stores them in trafficLights ref
   *
   * @returns {} Array of TrafficLight objects.
   */
  async function fetchAndStoreTrafficLights(): Promise<TrafficLight[]> {
    const resp = await customFetch(`${apiAddress}/TrafficLights`, {
      method: "GET",
    });

    if (resp.ok) {
      const data = (await resp.json()) as TrafficLight[];
      trafficLights.value = data;
      return trafficLights.value;
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * Fetches the list of available signal-light hardware onBoard and stores them in signalLights ref
   *
   * @returns {} Array of TrafficLight objects.
   */
  async function fetchAndStoreSignalLights(): Promise<SignalLight[]> {
    const resp = await customFetch(`${apiAddress}/SignalLights`, {
      method: "GET",
    });

    if (resp.ok) {
      const data = (await resp.json()) as SignalLight[];
      signalLights.value = data;
      return signalLights.value;
    } else {
      throw new Error(await resp.text());
    }
  }

  async function fetchTrafficLightStatuses() {
    const resp = await customFetch(`${apiAddress}/TrafficLights/TrafficLightStatuses`, {
      method: "GET",
    });
    if (resp.ok) {
      const trafficLightStatus = (await resp.json()) as TrafficLightStatusEvent[];
      for (const status of trafficLightStatus) {
        const existingTrafficLightIdtoStatus = trafficLightIdToStatus.value.find(
          (t) => t.trafficLightId === status.trafficLightId,
        );
        if (existingTrafficLightIdtoStatus) {
          existingTrafficLightIdtoStatus.status = status.status;
        } else {
          trafficLightIdToStatus.value.push(status);
        }
      }
    } else {
      throw new Error(await resp.text());
    }
  }

  async function fetchCurrentVesselLocation(): Promise<VesselLocation | null> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/VesselLocation`, {
        method: "GET",
      });

      if (resp.ok) {
        if (resp.status === 204) {
          vesselLocation.value = null;
          return null;
        } else {
          const data = (await resp.json()) as VesselLocation;
          vesselLocation.value = data;
          return data;
        }
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  async function fetchVesselLocationMode(): Promise<boolean> {
    const resp = await customFetch(`${apiAddress}/VesselLocation/configuration`);
    if (resp.ok) {
      isVesselLocationAutoMode.value = (
        (await resp.json()) as { isVesselLocationSetAutomatically: boolean }
      ).isVesselLocationSetAutomatically;
      return isVesselLocationAutoMode.value;
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * Defines if vesselLocation should be set automatically via GPS location input or manually
   * 16.12.2024: Currently not possible to set vesselLocationMode in frontend
   */
  async function setVesselLocationMode(isGangwayLocationAutoMode: boolean) {
    await customFetch(`${apiAddress}/VesselLocation/configuration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isVesselLocationSetAutomatically: isGangwayLocationAutoMode }),
    });
  }

  /**
   * Set the current global vesselLocation, used when gangway is in auto mode and to suggest location when approving gangwayEvents
   * @param location name of the location
   * @param potentiallySaveLocation if true, backend will try to add this location to list of custom gangwayLocations
   * @returns The new vesselLocation
   */
  async function setCurrentVesselLocation(
    location: string,
    potentiallySaveLocation: boolean = false,
  ): Promise<VesselLocation> {
    const resp = await customFetch(`${apiAddress}/VesselLocation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        label: location,
        potentiallySaveLocationToListOfGangwayLocations: potentiallySaveLocation,
      }),
    });

    if (resp.ok) {
      const data = (await resp.json()) as VesselLocation;
      vesselLocation.value = data;
      return data;
    } else {
      throw new Error(await resp.text());
    }
  }

  async function getCachedSpatialDataObjectWithinRange() {
    const resp = await customFetch(`${apiAddress}/VesselLocation/cached/spatialObject`);
    if (resp.ok) {
      if (resp.status === 204) {
        spatialDataObjectWithinRange.value = null;
      } else {
        spatialDataObjectWithinRange.value = (await resp.json()) as SpatialDataObject;
      }
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * If id is undefined -> POST
   * If id is specified -> PUT
   * @param gangway
   */
  async function addOrUpdateGangway(gangway: GangwayWriteModel): Promise<Gangway | void> {
    let apiUrl = `${apiAddress}/Gangway`;
    let method: "POST" | "PUT" = "POST";
    if (gangway.id) {
      apiUrl += `/${gangway.id}`;
      method = "PUT";
    }
    isConfigBeingSaved.value = true;
    try {
      const resp = await customFetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gangway),
      });

      if (resp.ok) {
        if (resp.status !== 204) {
          return (await resp.json()) as Gangway;
        }
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      isConfigBeingSaved.value = false;
    }
  }

  /**
   *
   * @param gangwayId
   */
  async function removeGangway(gangwayId: string): Promise<void> {
    const apiUrl = `${apiAddress}/Gangway/${gangwayId}`;
    const method = "DELETE";

    const resp = await customFetch(apiUrl, {
      method: method,
    });

    if (resp.ok) {
      return;
    } else {
      throw new Error(await resp.text());
    }
  }

  function subscribeToGangwayConfigSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayConfigurationChangeEvent", (gangway: Gangway) => {
      // Do not do anything if gangways has not been loaded from api yet,
      // the GET action will just replace the array either way.
      if (gangways.value) {
        if (gangway.deletedUtcDateTime) {
          // Remove config
          const existingIdx = gangways.value.findIndex((g) => g.id === gangway.id);
          if (existingIdx > -1) {
            gangways.value?.splice(existingIdx, 1);
          }
        } else {
          const existingGangway = gangways.value.find((g) => g.id === gangway.id);
          if (existingGangway) {
            // Exists, update it
            existingGangway.label = gangway.label;
            existingGangway.active = gangway.active;
            existingGangway.approvalMode = gangway.approvalMode;
            existingGangway.onboardingNodes = gangway.onboardingNodes;
            existingGangway.dropoffNodes = gangway.dropoffNodes;
            existingGangway.x = gangway.x;
            existingGangway.y = gangway.y;
            existingGangway.rssiLimit = gangway.rssiLimit;
            existingGangway.gangwayConfigurationStatus = gangway.gangwayConfigurationStatus;
            existingGangway.customGangwayLocation = gangway.customGangwayLocation;
          } else {
            // New config, add it
            gangways.value.push(gangway);
          }
        }
      }
    });
  }

  function subscribeToGangwayTrafficLightSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveTrafficLightStatusEvent", (statusEvent: TrafficLightStatusEvent) => {
      const existingTrafficLightIdtoStatus = trafficLightIdToStatus.value.find(
        (t) => t.trafficLightId === statusEvent.trafficLightId,
      );
      if (existingTrafficLightIdtoStatus) {
        existingTrafficLightIdtoStatus.status = statusEvent.status;
      } else {
        trafficLightIdToStatus.value.push(statusEvent);
      }
    });
  }

  function subscribeToIoDeviceConnectionChangeSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceiveIoDeviceConnectionChangeEvent",
      (connectionChangeEvent: IoDeviceConnectionChangeEvent) => {
        // one of the io devices was disconencted or connected
        // we can not tell which device was affected, so we need to fetch all traffic lights again
        fetchAndStoreSignalLights();
        fetchAndStoreTrafficLights();
      },
    );
  }

  function subscribeToGangwayActionSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayEvent", (gangwayAction: GangwayAction) => {
      // Do not do anything if gangways has not been loaded from api yet,
      // the GET action will just replace the array either way.
      if (gangwayActionsFromApi.value) {
        if (gangwayAction.deletedUtcDateTime || gangwayAction.state !== GangwayEventState.Unhandled) {
          // Remove action
          const existingIdx = gangwayActionsFromApi.value.findIndex((g) => g.id === gangwayAction.id);
          if (existingIdx > -1) {
            gangwayActionsFromApi.value?.splice(existingIdx, 1);
          }
        } else {
          const existingGangwayAction = gangwayActionsFromApi.value.find((g) => g.id === gangwayAction.id);
          if (existingGangwayAction) {
            // Exists, update it
            existingGangwayAction.state = gangwayAction.state;
          } else {
            // New action, add it
            gangwayActionsFromApi.value.push(gangwayAction);
          }
        }
      }
    });
  }

  function subscribeToGangwayLocationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayLocationChangeEvent", (gangwayLocation: GangwayLocation) => {
      // Do not do anything if gangwayLocations has not been loaded from api yet,
      // the GET gangwayLocation will just replace the array either way.
      // SpatialDataObjects will not be updated here, as they will be synced from cloud and will trigger a fetchDataFromApi
      if (gangwayLocations.value) {
        if (gangwayLocation.deletedUtcDateTime) {
          // Remove location
          const existingIdx = gangwayLocations.value.gangwayLocations.findIndex(
            (g) => g.id === gangwayLocation.id,
          );
          if (existingIdx > -1) {
            gangwayLocations.value?.gangwayLocations.splice(existingIdx, 1);
          }
        } else {
          const existing = gangwayLocations.value.gangwayLocations.find((g) => g.id === gangwayLocation.id);
          if (existing) {
            // Exists, update it
            existing.label = gangwayLocation.label;
            existing.yellowLimit = gangwayLocation.yellowLimit;
            existing.redLimit = gangwayLocation.redLimit;
          } else {
            // New location, add it
            gangwayLocations.value.gangwayLocations.push(gangwayLocation);
          }
        }
      }
    });
  }

  function subscribeToGangwayConfigurationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceiveVesselLocationConfigurationChangeEvent",
      (configuration: { isVesselLocationSetAutomatically: boolean }) => {
        isVesselLocationAutoMode.value = configuration.isVesselLocationSetAutomatically;
      },
    );
  }

  // here we update only gangway actions
  // persons state is updated in desktopStore.js
  function subscribeToPersonConfigurationSignalRMessages() {
    signalRSocketHandler.on("ReceivePersonConfigurationChangeEvent", (person: ApiPerson) => {
      potentiallyUpdatePersonActionFromSignalR(person.lastPersonAction);
    });
  }

  function subscribeToPersonActionSignalRMessages() {
    signalRSocketHandler.on("ReceivePersonActionChangeEvent", (action: ApiPersonAction) => {
      potentiallyUpdatePersonActionFromSignalR(action);
    });
  }

  function subscribeToVesselLocationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveVesselLocationChangeEvent", (location: VesselLocation) => {
      vesselLocation.value = location;
    });
  }

  function subscribeToSpatialDataObjectWithinRangeSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceivePossibleSpatialObjectChangeEvent",
      (spatialDataObject: SpatialDataObject | null) => {
        spatialDataObjectWithinRange.value = spatialDataObject;
      },
    );
  }

  function subscribeToReconnectAndBroadSignalREvents() {
    signalRSocketHandler.subscribe("reconnect", async () => {
      await fetchDataFromApi();
    });
    signalRSocketHandler.subscribe("statuschange", async (status) => {
      if (status.connected && !gangwayActionsFromApi.value && !isLoading.value) {
        await fetchDataFromApi();
      }
    });
    signalRSocketHandler.on("ReceiveConfigurationChangeEvent", async () => {
      // Fetch all data again as there have been a CloudSync event
      await fetchDataFromApi();
    });

    subscribeToGangwayConfigSignalRMessages();
    subscribeToGangwayTrafficLightSignalRMessages();
    subscribeToIoDeviceConnectionChangeSignalRMessages();
    subscribeToGangwayActionSignalRMessages();
    subscribeToPersonActionSignalRMessages();
    subscribeToPersonConfigurationSignalRMessages();
    subscribeToGangwayLocationSignalRMessages();
    subscribeToVesselLocationSignalRMessages();
    subscribeToGangwayConfigurationSignalRMessages();
    subscribeToSpatialDataObjectWithinRangeSignalRMessages();
  }

  function potentiallyUpdatePersonActionFromSignalR(action: ApiPersonAction | undefined | null) {
    // Do not do anything if gangways has not been loaded from api yet,
    // the GET action will just replace the array either way.

    if (!action) return;
    if (!last500PersonActions.value) return;

    const paId = action.id;
    const paDateTime = action.dateTimeUtc;
    const existing = last500PersonActions.value.find((p) => p.id === paId);

    // Action is already in the list
    if (existing) {
      // update PersonAction
      existing.location = action.location;
      return;
    }

    // PersonAction is not among last 50 actions, should add it
    const lastPaInList = last500PersonActions.value[last500PersonActions.value.length - 1];
    const person = store.getters.getPersonById(action.personId) as DashboardPerson | undefined | null;
    if (!person) {
      console.error("Can not update person action, person not found in store.");
      return;
    }

    if (!lastPaInList || paDateTime > lastPaInList.dateTimeUtc) {
      // PersonAction is newer than the the latest action
      const personActionToAddToList = {
        ...action,
        person: {
          id: person.id,
          fullName: person.fullName,
        },
      };

      for (let i = 0; i < last500PersonActions.value.length; i++) {
        // Insert personAction at correct location in last500PersonActions, as long as it is sorted on dateTimeUtc
        // Remove last personAction in list if more than 500 elements
        const element = last500PersonActions.value[i];
        if (paDateTime > element.dateTimeUtc) {
          last500PersonActions.value = [
            ...last500PersonActions.value.slice(0, i),
            personActionToAddToList,
            ...last500PersonActions.value.slice(
              i,
              last500PersonActions.value.length - (last500PersonActions.value.length <= 499 ? 0 : 1),
            ),
          ];
          break;
        } else if (i == last500PersonActions.value.length) {
          last500PersonActions.value.push(personActionToAddToList);
        }
      }
    }
  }

  /**
   *
   * @param trafficLightId The traffic light id to get the status for
   * @returns The status of the traffic light
   */
  function getTrafficLightStatus(trafficLightId: string): TrafficLightStatus | undefined {
    return trafficLightIdToStatus.value.find((s) => s.trafficLightId === trafficLightId)?.status;
  }

  /**
   *
   * @param location The location to get the traffic light for
   * @returns The traffic lights for the location, each location can have multiple TrafficLights. Where all of them share the same POB limits, but can have different override buttons
   */
  function getTrafficLightsConnectedToLocation(location: string): TrafficLight[] | undefined {
    return trafficLights.value?.filter((t) => t.dropOffLocation?.label === location);
  }

  return {
    isLoading,
    isConfigBeingSaved,
    approveGangwayActions,
    fetchGangwayActions,
    fetchPersonActions,
    fetchGangways,
    gangwayActions,
    addOrUpdateGangway,
    removeGangway,
    last500PersonActions,
    gangwayLocations,
    gangways,
    vesselLocation,
    setCurrentVesselLocation,
    deleteGangwayLocation,
    updateGangwayLocation,
    addNewGangwayLocation,
    setVesselLocationMode,
    fetchVesselLocationMode,
    isGangwayLocationAutoMode: isVesselLocationAutoMode,
    spatialDataObjectWithinRange,
    getTrafficLightsConnectedToLocation,
    getTrafficLightStatus,
    trafficLights,
    signalLights,
    trafficLightIdToStatus,
  };
}

/**
 * Returns a customGangwayLocation by its locationName
 * Useful when you want to retrieve yellowLimit and redLimit for a specific location
 */
export function getGangwayLocationByName(locationName: string) {
  return gangwayLocations.value?.gangwayLocations.find((l) => l.label === locationName);
}

export async function fetchSiteName(): Promise<string> {
  const resp = await customFetch(`${genericApiAddress}/Sites`, {
    method: "GET",
  });

  if (resp.ok) {
    const sites = (await resp.json()) as ApiSite[];
    return sites.length > 0 ? sites[0].name : "";
  } else {
    throw new Error(await resp.text());
  }
}

/**
 * Retrieves a matching gangway location or spatial location based on the provided vessel location.
 *
 * @param vesselLocation - The location of the vessel as a string, or null if not available.
 * @param gangwayLocations - An object containing gangway locations and spatial locations, or null if not available.
 * @returns A matching `GangwayLocation` object, a `SpatialDataObject` with a combined label, or null if no match is found.
 */
export function getMatchingSpatialLocationOrGangwayLocation(
  vesselLocation: string | null,
  gangwayLocations: GangwayLocationWrapper | null,
): GangwayLocation | (SpatialDataObject & { combinedLabel: string }) | null {
  if (!vesselLocation || !gangwayLocations) {
    return null;
  }
  for (const location of gangwayLocations.gangwayLocations) {
    if (location.label === vesselLocation) {
      return location;
    }
  }
  const [groupName, locationName] = vesselLocation.split(" - ");

  for (const spatialLocation of gangwayLocations.spatialLocations) {
    if (spatialLocation.label === groupName) {
      for (const spatialDataObject of spatialLocation.spatialDataObjects) {
        if (spatialDataObject.label === locationName) {
          return { ...spatialDataObject, combinedLabel: groupName + " - " + locationName };
        }
      }
    }
  }
  return null;
}
export interface BoardingActionFullReadModel extends ApiPersonAction {
  person: ApiPerson;
  gangwayEvent?: GangwayAction | null;
}

export interface ApiSite {
  id: string;
  customerId: string;
  name: string;
}
