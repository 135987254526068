import { DashboardNode, useStore } from "@/store/desktopStore";
import { Guid } from "@/typedef";
import { computed, ref } from "vue";
import { CabinBunk, MusterStationPerMusterTypeWriteModel } from "./useBunks.d";

// TODO: Maybe fetch muster stations from backend when backend is updated
export default function () {
  const store = useStore();
  const nodes = computed(() => store.getters.nodes as DashboardNode[]);
  const musterStations = computed(() =>
    nodes.value.filter((node) => node.musterStation).map((node) => node.musterStation),
  );

  return {
    musterStations,
  };
}

/**
 * A shared ref to store the updated muster stations per muster type when editing a person or bunk
 * Not an optimal way to store this data, but it's a quick solution for now
 */
export const updatedMusterStationsPerMusterTypes = ref<
  {
    musterTypeId: Guid;
    primaryMusterStationId: Guid | null;
    secondaryMusterStationId: Guid | null;
    /**
     * If we have a mix of muster stations for this musterType show different UI
     */
    isPrimaryMixed?: boolean;
    isSecondaryMixed?: boolean;
  }[]
>([]);

export function handleMusterStationUpdate(
  musterTypeId: string,
  musterStationId: string | null,
  isPrimary: boolean,
) {
  const musterStationPerMusterType = updatedMusterStationsPerMusterTypes.value.find(
    (muster) => muster.musterTypeId === musterTypeId,
  );
  if (!musterStationPerMusterType) {
    updatedMusterStationsPerMusterTypes.value.push({
      musterTypeId,
      primaryMusterStationId: isPrimary ? musterStationId : null,
      secondaryMusterStationId: isPrimary ? null : musterStationId,
    });
  } else {
    if (isPrimary) {
      musterStationPerMusterType.primaryMusterStationId = musterStationId;
      musterStationPerMusterType.isPrimaryMixed = false;
    } else {
      musterStationPerMusterType.secondaryMusterStationId = musterStationId;
      musterStationPerMusterType.isSecondaryMixed = false;
    }
  }
}

/**
 * Run every time bunks are updated to update the muster stations per muster type used for selection
 * @param bunks
 */
export function setupMusterStationsPerMusterTypes(bunks: CabinBunk[]) {
  updatedMusterStationsPerMusterTypes.value = [];
  for (const bunk of bunks) {
    if (bunk.defaultMusterStationPerMusterType) {
      for (const musterType of bunk.defaultMusterStationPerMusterType) {
        const existingMusterType = updatedMusterStationsPerMusterTypes.value.find(
          (mt) => mt.musterTypeId === musterType.musterTypeId,
        );
        if (!existingMusterType) {
          updatedMusterStationsPerMusterTypes.value.push({
            musterTypeId: musterType.musterTypeId,
            primaryMusterStationId: musterType.primaryMusterStationId,
            secondaryMusterStationId: musterType.secondaryMusterStationId,
          });
        } else {
          if (existingMusterType.primaryMusterStationId !== musterType.primaryMusterStationId) {
            existingMusterType.primaryMusterStationId = null;
            existingMusterType.isPrimaryMixed = true;
          }
          if (existingMusterType.secondaryMusterStationId !== musterType.secondaryMusterStationId) {
            existingMusterType.secondaryMusterStationId = null;
            existingMusterType.isSecondaryMixed = true;
          }
        }
      }
    }
  }
}

/**
 * Use this function to calculate correct musterStations per musterType when updating bunks
 * based on input fields.
 * @param existingMusterStationsPerMusterTypes
 * @param musterStationPerMusterTypeInputs
 */
export function getMusterStationPerMusterTypeFromSelection(
  existingMusterStationsPerMusterTypes: MusterStationPerMusterTypeWriteModel[],
  musterStationPerMusterTypeInputs: {
    musterTypeId: Guid;
    primaryMusterStationId: Guid | null;
    secondaryMusterStationId: Guid | null;
    isPrimaryMixed?: boolean;
    isSecondaryMixed?: boolean;
  }[],
) {
  let internalMusterStationsPerMusterTypes = [...existingMusterStationsPerMusterTypes];
  musterStationPerMusterTypeInputs.forEach((muster) => {
    const existingMusterStationPerMusterType = internalMusterStationsPerMusterTypes.find(
      (musterType) => musterType.musterTypeId === muster.musterTypeId,
    );

    /**
     * If primary is not defined and not mixed -> Delete muster station per musterType
     */
    const shouldDeleteMusterStationPerMusterType = !muster.primaryMusterStationId && !muster.isPrimaryMixed;

    if (shouldDeleteMusterStationPerMusterType) {
      internalMusterStationsPerMusterTypes = internalMusterStationsPerMusterTypes.filter(
        (musterType) => musterType.musterTypeId !== muster.musterTypeId,
      );
    } else if (existingMusterStationPerMusterType) {
      // If primary is defined -> Update to new primary
      // If primary is not defined -> keep existing primary -> Means it is mixed
      existingMusterStationPerMusterType.primaryMusterStationId = muster.primaryMusterStationId
        ? muster.primaryMusterStationId
        : existingMusterStationPerMusterType.primaryMusterStationId;

      // If secondary is mixed and defined -> update secondary
      // If secondary is not mixed or not defined -> keep existing secondary
      existingMusterStationPerMusterType.secondaryMusterStationId =
        muster.isSecondaryMixed && !muster.secondaryMusterStationId
          ? existingMusterStationPerMusterType.secondaryMusterStationId
          : muster.secondaryMusterStationId;
    } else if (muster.primaryMusterStationId) {
      internalMusterStationsPerMusterTypes.push({
        musterTypeId: muster.musterTypeId,
        primaryMusterStationId: muster.primaryMusterStationId,
        secondaryMusterStationId: muster.secondaryMusterStationId,
      });
    }
  });

  return internalMusterStationsPerMusterTypes;
}
