import { render, staticRenderFns } from "./SystemStatusIssueDetails.vue?vue&type=template&id=324d3364&scoped=true&"
import script from "./SystemStatusIssueDetails.vue?vue&type=script&lang=ts&"
export * from "./SystemStatusIssueDetails.vue?vue&type=script&lang=ts&"
import style0 from "./SystemStatusIssueDetails.vue?vue&type=style&index=0&id=324d3364&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324d3364",
  null
  
)

export default component.exports